.module-card{
  /*width: 400px;
  height: 400px;*/
  border: 1px solid var(--main-blue);
}

.module-card__header{
  background: var(--main-blue);
  padding: 5px 20px;
  min-height: 44px;
  display: flex;
  justify-content: space-between;
}

.module-card__header-left{
  display: flex;
}

.module-card__main{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33% 0;
  flex-direction: column;
  text-align: center;
}

.module-card__title a{
  display: block;
  font-size: 40px;
  color: var(--main-blue);
  text-decoration: none;
  margin: 0 0 20px 0;
}

.module-card__category{
  color: var(--main-blue);
  font-size: 25px;
}
.page-header{
  background: var(--main-blue);
}

.page-header__inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 1700px;
  height: 100px;
  margin: 0 auto;
}

.page-header__inner a{
  color: var(--font-white);
  font-size: 40px;
  text-decoration: none;
}
.page-footer{
  height: 100px;
  background: var(--main-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-footer a{
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}
:root{
  --main-blue: #0678be;
  --font-white: #FFF;
  --warning-light: rgba(255,0,0,0.5);
  --warning-red: rgba(255,0,0,1);
}
*{
  margin: 0;
  padding: 0;
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
  -webkit-margin-end: 0;
  -webkit-margin-start: 0;
}

.warning{
  width: 95%;
  max-width: 1700px;
  margin: 20px auto 0 auto;
  background: var(--warning-light);
  border-left: 1vw solid var(--warning-red);
  padding: 20px 0;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  color: var(--font-white);
}

.moduleContainer{
  width: 100%;
  max-width: 1700px;
  margin: 40px auto;
}

.moduleContainer .inner-wrapper{
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
}

@media all and (min-width: 560px){
  .moduleContainer .inner-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 4%;
  }
}

@media all and (min-width: 960px){
  .moduleContainer .inner-wrapper{
    width: 95%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px 4%;
  }
}

@media all and (min-width: 1700px){
  .moduleContainer .inner-wrapper{
    width: 100%;
  }
}
