.page-header{
  background: var(--main-blue);
}

.page-header__inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 1700px;
  height: 100px;
  margin: 0 auto;
}

.page-header__inner a{
  color: var(--font-white);
  font-size: 40px;
  text-decoration: none;
}