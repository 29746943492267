*{
  margin: 0;
  padding: 0;
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
  -webkit-margin-end: 0;
  -webkit-margin-start: 0;
}

.warning{
  width: 95%;
  max-width: 1700px;
  margin: 20px auto 0 auto;
  background: var(--warning-light);
  border-left: 1vw solid var(--warning-red);
  padding: 20px 0;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  color: var(--font-white);
}

.moduleContainer{
  width: 100%;
  max-width: 1700px;
  margin: 40px auto;
}

.moduleContainer .inner-wrapper{
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
}

@media all and (min-width: 560px){
  .moduleContainer .inner-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 4%;
  }
}

@media all and (min-width: 960px){
  .moduleContainer .inner-wrapper{
    width: 95%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px 4%;
  }
}

@media all and (min-width: 1700px){
  .moduleContainer .inner-wrapper{
    width: 100%;
  }
}