.page-footer{
  height: 100px;
  background: var(--main-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-footer a{
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}