.module-card{
  /*width: 400px;
  height: 400px;*/
  border: 1px solid var(--main-blue);
}

.module-card__header{
  background: var(--main-blue);
  padding: 5px 20px;
  min-height: 44px;
  display: flex;
  justify-content: space-between;
}

.module-card__header-left{
  display: flex;
}

.module-card__main{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33% 0;
  flex-direction: column;
  text-align: center;
}

.module-card__title a{
  display: block;
  font-size: 40px;
  color: var(--main-blue);
  text-decoration: none;
  margin: 0 0 20px 0;
}

.module-card__category{
  color: var(--main-blue);
  font-size: 25px;
}